<template>
  <v-row>
    <v-col cols="12">
      <login-page></login-page>
    </v-col>
  </v-row>
</template>

<script>
import LoginPage from './components/LoginPage.vue'

export default {
  components: {
    LoginPage,
  },
  setup() {
    return {}
  },
  mounted(){
    if(localStorage.getItem('token')){
      this.$router.push({name:'dashboard'});
    }
  },
}
</script>
