<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7"> </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2 text-center">Login</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="username"
              outlined
              label="Username"
              placeholder="Username"
              hide-details="auto"
              :rules="[v => !!v || 'Username belum diisi']"
              class="mb-3"
              required
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details="auto"
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="[v => !!v || 'Password belum diisi']"
              required
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap"></div>

            <v-btn block color="primary" class="mt-6" @click="validate" :disabled="isLoading"> Login </v-btn>
          </v-form>
          <div class="d-flex justify-center mt-5">
            <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
            <v-alert v-if="alertStatus" :type="alertType" class="full-width">
              {{ alertMessage }}
              <button
                style="float: right"
                type="button"
                v-on:click="closeAlert"
                class="close right"
                data-dismiss="alert-success"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </v-alert>
          </div>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const username = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      username,
      password,
      socialLink,
      valid: true,
      isLoading: false,
      alertStatus: false,
      alertType: 'error',
      alertMessage: 'Loading . . .',

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  mounted() {},
  methods: {
    validate() {
      let checkValid = this.$refs.form.validate()
      if (checkValid) {
        this.submit()
      }
    },
    submit() {
      this.isLoading = true
      this.alertStatus = false
      axios
        .post(process.env.VUE_APP_API_ENDPOINT + 'users/login', { password: this.password, username: this.username })
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            const data = response.data.data
            if (response.data.success == 1) {
              localStorage.setItem('token', data.token)
              let user = {
                name: data.name,
                role: data.role_name,
                status: data.status,
                username: data.username,
              }
              localStorage.setItem('user', JSON.stringify(user))
              setTimeout(() => {
                this.alertType = 'success'
                this.alertMessage = response.data.message
                this.$router.push({ name: 'dashboard' })
                this.isLoading = false
                this.alertStatus = true
                this.resetForm()
              }, 2000)
            } else {
              setTimeout(() => {
                this.isLoading = false
                this.alertType = 'error'
                this.alertMessage = response.data.message
                this.alertStatus = true
                this.resetForm()
              }, 2000)
            }
          } else {
            setTimeout(() => {
              this.isLoading = false
              this.alertType = 'error'
              this.alertMessage = response.statusText
              this.alertStatus = true
              this.resetForm()
            }, 2000)
          }
        })
        .catch(error => {
          const response = error.response
          const data = response.data
          setTimeout(() => {
            this.isLoading = false
            this.alertType = 'error'
            this.alertMessage = data.message
            this.alertStatus = true
            // this.resetForm()
          }, 2000)
        })
    },
    closeAlert() {
      this.alertStatus = false
    },
    resetForm() {
      this.password = this.username = ''
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
